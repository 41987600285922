import '../sass/frontend.scss'

import Swiper, { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
import "@fancyapps/ui/dist/fancybox.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import lottie from "lottie-web";

gsap.registerPlugin(ScrollTrigger);


const case_swiper = new Swiper('.case-slider', {
	modules: [Pagination],
	pagination: {
		el: '.swiper-pagination',
	},
})

Fancybox.bind(".add-lightbox a", {
	groupAll: true,
	Toolbar: {
		display: [
			{ id: 'close', position: 'right' },
		]
	}
})

gsap.to(".ani-moveAfter", {
	scrollTrigger: {
		trigger: ".ani-moveAfter",
		toggleClass: 'move-down-left',
		start: '50px center',
		scrub: 1,
	},
});

gsap.to(".gb-container-d2a75ee8", {
	scrollTrigger: {
		trigger: ".anime-flyin-left",
		toggleClass: 'active',
		start: 'bottom bottom',
		scrub: 1,
	}
})

const homeAnimeOne = lottie.loadAnimation({
	container: document.querySelector('.anim-svg__uno'),
	path: '/wp-content/themes/blocksy-child/assets/src/json/anim-illustration-dos.json',
	renderer: 'svg',
	loop: true,
	autoplay: true,
})

const homeAnimeDos = lottie.loadAnimation({
	container: document.querySelector('.anim-svg__dos'),
	path: '/wp-content/themes/blocksy-child/assets/src/json/anim-illustration-uno.json',
	renderer: 'svg',
	loop: true,
	autoplay: true,
})

function animateFrom(elem, direction) {
	direction = direction || 1;
	var x = 0,
		y = direction * 100;
	if(elem.classList.contains("gs_reveal_fromLeft")) {
	  x = -100;
	  y = 0;
	} else if (elem.classList.contains("gs_reveal_fromRight")) {
	  x = 100;
	  y = 0;
	}
	elem.style.transform = "translate(" + x + "px, " + y + "px)";
	elem.style.opacity = "0";
	gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
	  duration: 1.25, 
	  x: 0,
	  y: 0, 
	  autoAlpha: 1, 
	  ease: "expo", 
	  overwrite: "auto"
	});
  }
  
  function hide(elem) {
	gsap.set(elem, {autoAlpha: 0});
  }
  
  document.addEventListener("DOMContentLoaded", function() {
	gsap.registerPlugin(ScrollTrigger);
	
	gsap.utils.toArray(".gs_reveal").forEach(function(elem) {
	  hide(elem); // assure that the element is hidden when scrolled into view
	  
	  ScrollTrigger.create({
		trigger: elem,
		onEnter: function() { animateFrom(elem) }, 
		onEnterBack: function() { animateFrom(elem, -1) },
		onLeave: function() { hide(elem) } // assure that the element is hidden when scrolled into view
	  });
	});
  });
  